<template>
  <div class="record-list">
    
    <app-list :opt="opt" @get="onGet">
      <!-- <template slot='top'>
        <div class="record-list_money">

          <div class="record-list__panel">
            <div class="record-list__panel-top">
              ￥
              <span class="number">{{monthWithdraw ? monthWithdraw : '0.00'}}</span>元
            </div>
            <div class="record-list__panel-bot">
              本月提款
            </div>
          </div>

          <div class="record-list__panel">
            <div class="record-list__panel-top">
              ￥
              <span class="number">{{newlyWithdraw ? newlyWithdraw : '0.00'}}</span>元
            </div>
            <div class="record-list__panel-bot">
                最近一次提款<span class="bignumber">{{newlyWithdrawTime}}</span>
            </div>
          </div>
          
          <div class="record-list__panel">
            <div class="record-list__panel-top">
              ￥
              <span class="number">{{totalWithdraw ? totalWithdraw : '0.00'}}</span>元
            </div>
            <div class="record-list__panel-bot">
              总提款金额
            </div>
          </div>

          <div class="record-list__panel">
            <div class="record-list__panel-top">
              ￥
              <span class="number">{{withdrawBalance ? withdrawBalance : '0.00'}}</span>元
            </div>
            <div class="record-list__panel-bot">
              剩余可提款金额
            </div>
            <el-button class="row-nav-vice"  @click="withdraw" type="success" size="mini">去提款</el-button>
          </div>

        </div>
      </template> -->
    </app-list>
  </div>
</template>
<script>
export default {
  data() {
    let _this = this;

    return {
      monthWithdraw: "",
      newlyWithdraw: "",
      newlyWithdrawTime: "",
      totalWithdraw: "",
      withdrawBalance: "",
      opt: {
        title: "提款记录",
        search: [
          {
            key: "date",
            label: "起止日期",
            type: "timer-month",
            timeType: "yyyy-MM"
          },
          {
            key: "status",
            label: "到账情况",
            type: "select-no-filterable",
            opt: {
              list: [
                {
                  label: "到账",
                  value: 1
                },
                {
                  label: "未到账",
                  value: 2
                }
              ]
            }
          }
        ],
        columns: [
          {
            label: "提款流水号",
            key: "withdrawId",
            on: row => {
              console.log("+" + row.withdrawId);
              _this.cache.set("statementDetailTitle", row.reconDate);
              _this.$router.push({
                path: "/main/record/detail/show/" + row.withdrawId,
                query: { withdrawId: row.withdrawId }
              });
            }
          },
          { label: "提款人", key: "withdrawAdult" },
          { label: "提款人手机", key: "withdrawAdult" },
          { label: "提款时间", key: "withdrawTime" },
          { label: "提款金额（元）", key: "withdrawAmount" },
          {
            label: "到账情况",
            key: "arrivalState"
          }
        ]
      }
    };
  },
  created() {
    console.log("record-list created!!");
  },
  methods: {
    onGet(opt) {
      let dto = {
        pageNum: opt.skip,
        pageSize: opt.limit,
        startTime: opt.searchForm["date"] ? opt.searchForm["date"][0] : null,
        endTime: opt.searchForm["date"] ? opt.searchForm["date"][1] : null,
        arrivalState: opt.searchForm["status"]
      };
      this.post("/finance-service/withdraw/withdrawRecordBack", dto, {
        isUseResponse: true
      }).then(res => {
        console.log(res);
        for (let i = 0; i < res.data.data.length; i++) {
          let item = res.data.data[i];
          item.reconDate = this.format(item.date, 1);
          if (item.arrivalState == '2') {
            item.bk = 2;
          }
          item.arrivalState = item.arrivalState == '1' ? "到账" : "未到帐";
        }
        opt.cb(res.data);
      });
      // this.post("/finance-service/withdraw/withdrawBalance", dto, {
      //   isUseResponse: true
      // }).then(res => {
      //   this.monthWithdraw = res.data.data.monthWithdraw;
      //   this.newlyWithdraw = res.data.data.newlyWithdraw;
      //   this.newlyWithdrawTime = res.data.data.newlyWithdrawTime;
      //   this.totalWithdraw = res.data.data.totalWithdraw;
      //   this.withdrawBalance = res.data.data.withdrawBalance;
      // });
    },
    //去提款
    withdraw() {
      this.$confirm(
        "尊敬的房东管理员，您好，电脑端暂不支持提款功能，请在微信小程序“云数e家”房东端“首页”-“收益中心”申请提款。",
        "提示",
        {
          confirmButtonText: "我知道了",
          cancelButtonText: "",
          type: "warning"
        }
      );
    }
  }
};
</script>
<style lang="scss" scoped>
.record-list_money {
  display: flex;
  justify-content: space-around;
  margin-bottom: 20px;
  border-right: solid 1px #eee;
}
.box {
  border: 1px solid #dcdfe6;
  width: 300px;
  height: 150px;
  text-align: center;
  line-height: 150px;
  position: relative;
}
.number {
  font-size: 35px;
  margin-right: 10px;
}
.word {
  position: relative;
  top: 38px;
  left: -72px;
}
//最近一次提款
.bigword {
  position: relative;
  top: 38px;
  left: -22%;
}
.bignumber {
  position: relative;
  bottom: 89%;
  left: 20%;
  color: #bebebe;
}
//去提款
.row-nav-vice {
  position: absolute;
  right: 4px;
  bottom: 4px;
  cursor: pointer;
  font-size: 11px;
}

.record-list__panel {
  position: relative;
  flex: 1;
  height: 150px;
  border-top: solid 1px #eee;
  border-left: solid 1px #eee;
  border-bottom: solid 1px #eee;
  box-sizing: border-box;

  .record-list__panel-top {
    margin: 30px 0;
    text-align: center;
  }

  .record-list__panel-bot {
    text-align: center;
  }
}
</style>
